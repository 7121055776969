.partners-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #f9f9f9;
}

.partners-title {
    font-size: 28px;
    color: #4E4187;
    margin-bottom: 30px;
    font-weight: bold;
}

.partners-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Более крупный зазор между логотипами */
    margin: 0 auto;
    max-width: 1200px;
}

.partner-logo-container {
    flex: 0 0 23%; /* Процентное соотношение для 4 логотипов в ряду */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: transparent; /* Убираем задний белый фон */
}

.partner-logo-container:hover {
    /* transform: translateY(-5px); */
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); */
}

.partner-logo {
    max-width: 100%;
    max-height: 100px; /* Ограничиваем высоту, чтобы логотипы были более равномерными */
    object-fit: contain;
    mix-blend-mode: multiply;
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    .partner-logo-container {
        flex: 0 0 45%; /* Два логотипа в ряд на планшетах */
        max-width: 45%;
    }

    .partner-logo {
        max-height: 80px; /* Уменьшаем высоту на меньших экранах */
    }
}

@media (max-width: 480px) {
    .partner-logo-container {
        flex: 0 0 25%; /* Один логотип в ряд на мобильных */
        max-width: 100%;
    }

    .partner-logo {
        max-height: 60px; /* Еще меньше на мобильных */
    }
}