.what-we-do {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .blocks-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Две карточки в строке */
    gap: 20px; /* Расстояние между карточками */
    max-width: 1200px;
    width: 100%;
  }
  
  .block {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .icon-container {
    margin-bottom: 15px;
    color: #4E4187;
  }
  
  .block-title {
    font-size: 24px;
    font-weight: bold;
    color: #4E4187;
    margin-bottom: 15px;
  }
  
  .block-description {
    font-size: 16px;
    color: #333;
  }
  
  /* Медиазапросы для мобильной версии */
  @media (max-width: 768px) {
    .blocks-grid {
      grid-template-columns: 1fr; /* Одна карточка в строке на мобильных */
    }
  }
  
  @media (max-width: 480px) {
    .block-title {
      font-size: 20px;
    }
  
    .block-description {
      font-size: 14px;
    }
  
    .icon-container {
      margin-bottom: 10px;
    }
  }