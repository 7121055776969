.hero-section {
    position: relative;
    width: 100%;
    /* height: 600px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.hero-content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 250px;
    padding: 0 20px;
    text-align: center;
}

.hero-chat-bubble {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px 25px;
    border-radius: 30px;
    font-size: 22px;
    color: #4E4187;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
}

.hero-chat-icon {
    margin-right: 15px;
    font-size: 60px;
    color: #4E4187;
    flex-shrink: 0;
    z-index: 3;
}

.hero-chat-text {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.hero-chat-bubble .Typed {
    display: inline-block;
    white-space: nowrap;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
    .hero-section {
        height: 500px;
    }

    .hero-content {
        width: 90%;
        height: auto;
    }

    .hero-chat-bubble {
        font-size: 18px;
        padding: 15px 20px;
        border-radius: 25px;
    }

    .hero-chat-icon {
        font-size: 40px;
        margin-right: 10px;
    }
}

@media (max-width: 480px) {
    .hero-section {
        height: 100px;
    }

    .hero-chat-bubble {
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 20px;
    }

    .hero-chat-icon {
        font-size: 30px;
        margin-right: 8px;
    }
}