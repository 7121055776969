/* WorkProcess.css */

.work-process-container {
    text-align: center;
    padding: 40px 20px;
  }
  
  .work-process-title {
    font-size: 28px;
    font-weight: bold;
    color: #4E4187;
    margin-bottom: 40px;
  }
  
  .work-process {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .work-process-step {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
  }
  
  .work-process-icon {
    width: 60px;
    height: 60px;
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
  }
  
  .work-process-name {
    font-size: 14px;
    color: #333;
    max-width: 100px;
  }
  
  .work-process-arrow {
    font-size: 24px;
    color: #ccc;
    position: absolute;
    right: -35px;
    top: 20px;
  }
  
  .work-process-step:last-child .work-process-arrow {
    display: none;
  }
  
  /* Адаптивность */
  
  @media (max-width: 768px) {
    .work-process {
      flex-direction: column;
    }
  
    .work-process-step {
      flex-direction: row;
      margin-bottom: 20px;
    }
  
    .work-process-icon {
      margin-right: 15px;
      margin-bottom: 0;
    }
  
    .work-process-arrow {
      display: none;
    }
  }
  
  @media (max-width: 480px) {
    .work-process-name {
      font-size: 16px;
      font-weight: 700;
    }
  
    .work-process-icon {
      width: 80px;
      height: 80px;
      font-size: 30px;
    }
  }