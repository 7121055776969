.why-choose-us-section {
    padding: 40px 20px;
    text-align: center;
}

.section-title {
    font-size: 28px;
    font-weight: bold;
    color: #4E4187;
    margin-bottom: 40px;
}

.why-choose-us {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.benefit-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.icon-container {
    font-size: 48px;
    color: #4E4187;
    margin-bottom: 10px;
}

.benefit-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}

.benefit-description {
    font-size: 14px;
    color: #666;
}

/* Медиазапросы для адаптации на мобильных устройствах */
@media (max-width: 768px) {
    .why-choose-us {
        grid-template-columns: repeat(2, 1fr); /* 2 колонки на планшетах и средних экранах */
        gap: 15px;
    }
}

@media (max-width: 480px) {
    .why-choose-us {
        grid-template-columns: 1fr; /* 1 колонка на мобильных устройствах */
        gap: 10px;
    }

    .icon-container {
        font-size: 36px; /* Уменьшение размера иконки на мобильных устройствах */
    }

    .benefit-title {
        font-size: 16px;
    }

    .benefit-description {
        font-size: 12px;
    }
}