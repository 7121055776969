/* Services.css */

.services {
  text-align: center;
  padding: 50px 20px;
  background-color: #f5f5f5;
}

.services h2 {
  font-size: 32px;
  color: #4e4187;
  margin-bottom: 40px;
  font-weight: bold;
  text-transform: uppercase;
}

.requisites-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.requisite-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.requisite-item:hover {
  transform: translateY(-5px);
}

.requisite-icon {
  font-size: 28px;
  color: #3083DC;
  margin-right: 15px;
}

.requisite-info {
  text-align: left;
}

.requisite-label {
  font-size: 16px;
  color: #4e4187;
}

.requisite-value {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

/* Адаптивность */
@media (max-width: 768px) {
  .requisites-block {
    grid-template-columns: 1fr;
  }

  .requisite-item {
    padding: 10px;
  }

  .requisite-icon {
    font-size: 24px;
  }

  .requisite-label {
    font-size: 14px;
  }

  .requisite-value {
    font-size: 16px;
  }
}