.footer {
    background-color: #4E4187;
    color: #fff;
    padding: 20px;
    position: relative;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

/* Для экранов шириной до 768px */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-contacts, .footer-copy {
        width: 100%;
        margin-bottom: 20px;
        text-align: left;
    }

    .scroll-to-top {
        right: 20px;
        bottom: 20px;
    }
}

/* Для экранов шириной до 480px */
@media (max-width: 480px) {
    .footer-contacts p {
        font-size: 12px;
    }

    .footer-copy {
        font-size: 12px;
        text-align: center;
    }

    .scroll-to-top {
        right: 10px;
        bottom: 10px;
        padding: 8px;
        font-size: 16px;
    }
}

.footer-contacts {
    flex: 1;
    margin-bottom: 10px;
}

.footer-contacts h3 {
    margin-bottom: 10px;
}

.footer-contacts p {
    margin: 5px 0;
    font-size: 14px;
}

.footer-contacts p svg {
    margin-right: 8px;
}

.footer-copy {
    flex: 1;
    text-align: center;
    font-size: 14px;
}

.scroll-to-top {
    background-color: #ffffff;
    color: #4E4187;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 18px;
    transition: background-color 0.3s, transform 0.3s;
}

.scroll-to-top:hover {
    background-color: #4E4187;
    color: #ffffff;
    transform: translateY(-3px);
}