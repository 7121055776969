.header {
    width: 100%;
    padding: 20px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 40px;
    color: #5b5c58;
    flex-wrap: wrap;
}

/* Логотип */
.logo {
    font-size: 55px;
    font-weight: bold;
    color: #4E4187;
    text-decoration: none;
    white-space: nowrap;
}

/* Информация */
.info {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

/* Элемент информации */
.info-item {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
}

/* Иконки */
.icon {
    font-size: 18px;
}

/* Контейнер для кнопок и языков */
.buttons-container {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

/* Основные кнопки */
.cta-button,
.services-button {
    background-color: #5b5c58;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    text-decoration: none;
}

.cta-button:hover,
.services-button:hover {
    background-color: #3083DC;
}

.services-button {
    margin-right: 5px;
}

/* Языковые переключатели */
.language-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.language-buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 18px;
    min-width: 60px;
    transition: transform 0.2s ease;
}

.language-buttons button:hover {
    transform: scale(1.2);
}

.language-buttons button .icon {
    margin-right: 5px;
}

/* Мобильная версия */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .logo {
        font-size: 50px;
        margin-bottom: 15px;
    }

    .info {
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        margin-bottom: 15px;
    }

    .cta-button,
    .services-button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .language-buttons {
        gap: 10px;
    }

    .language-buttons button {
        font-size: 16px;
    }
}

/* Более компактная версия для маленьких мобильных устройств */
@media (max-width: 480px) {
    .logo {
        font-size: 40px;
        margin-bottom: 10px;
    }

    .cta-button,
    .services-button {
        font-size: 14px;
        padding: 8px 12px;
    }

    .info {
        flex-direction: row;
        gap: 8px;
        justify-content: center;
    }

    .icon {
        font-size: 16px;
    }

    .info-item span {
        font-size: 17px;
    }

    .language-buttons {
        flex-direction: row;
        gap: 10px;
        justify-content: center;
    }

    .language-buttons button {
        font-size: 14px;
    }
}