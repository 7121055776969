/* src/App.css */

body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F0F0F0;
  
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body, .header, .cta-button {
    font-family: 'Montserrat', sans-serif;
}